<style>
  .notice-style {
    background: #0065ac;
    color: #FFFFFF;
    font-size: 160%;
  }
</style>

<template>
  <div class="app-container">

    <!-- Synchronize To CDN Status -->
    <template v-if="syncCDNStatus === APPLY_STATUS_ENABLE">
      <el-card class="box-card">
          <div>
              <el-button type="primary" size="small" @click="onSyncCDN">Synchronize To Remote CDN Server</el-button>
          </div>
      </el-card>
      <br/>
    </template>

    <template v-if="syncCDNStatus === APPLY_STATUS_PROCESSING">
      <el-card class="box-card notice-style">
          <div>
              <span>Synchronize CDN Processing...{{syncCDNProcessingTimeInc}}s</span>
          </div>
      </el-card>
      <br/>
    </template>

    <template v-if="syncCDNStatus === APPLY_STATUS_DONE">
      <el-card class="box-card notice-style">
          <div>
              <span>Synchronization To Remote CDN Server Has Done.</span>
          </div>
      </el-card>
      <br/>
    </template>
    <!-- End Synchronize To CDN Status -->

    <el-tabs v-model="activeNames" @tab-click="handleClick" v-loading="loading">
      <el-tab-pane label="Asset List" name="1">

        <el-row :gutter="20">
          <el-col>
            <span>Last Modified: {{ modify }}</span>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input placeholder="" v-model="searchString"></el-input>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" icon="el-icon-search" @click="onSearch()"
              >Search</el-button
            >
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <template v-if="isHolding == 1">
              <el-button type="primary" @click="onHold()"
                >Holding - Click to active</el-button
              >
            </template>
            <template v-else>
              <el-button type="danger" @click="onHold()">
                Activing - Click to hold</el-button
              >
            </template>
            <span> [This will be cancel all editing assets]</span>
          </el-col>
        </el-row>

        <el-table :data="configDataList" :default-sort = "{prop: 'id', order: 'descending'}">
          <el-table-column prop="id" label="ID" width="100px" sortable>
            <template slot-scope="{ row }">
              {{ row.config_id }}
            </template>
          </el-table-column>

          <el-table-column prop="asset_group" label="Asset Group" width="150px" sortable>
            <template slot-scope="{ row }">
              <span>{{ row.config_AssetGroup }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Asset Description" width="300px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                <el-input
                  v-model="row.config_AssetDescription"
                  class="edit-input"
                  size="small"
                />
              </template>
              <span v-else>{{ row.config_AssetDescription }}</span>
            </template>
          </el-table-column>

          <el-table-column
            label="Event ID"
            prop="config_EventID"
            width="120px"
            sortable
          >
            <template slot-scope="{ row }">
              <span>{{ row.config_EventID }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="download_type" label="Download Type" width="200px" sortable>
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                <el-select
                  v-model="row.config_DownloadType"
                  placeholder="Select"
                  value-key="row.config_DownloadType"
                >
                  <el-option
                    v-for="item in downloadTypeList"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </template>
              <span v-else>{{ row.config_DownloadType }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Affect Game Version" width="200px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                From:
                <el-input
                  v-model="row.config_fromVersion"
                  class="edit-input"
                  size="small"
                />
                <br /><br />
                To:
                <el-input
                  v-model="row.config_toVersion"
                  class="edit-input"
                  size="small"
                />
              </template>
              <span v-else
                >From: {{ row.config_fromVersion }} <br /><br />
                To: {{ row.config_toVersion }}</span
              >
              <br />
            </template>
          </el-table-column>

          <el-table-column label="Bundle Name" width="200px">
            <template slot-scope="{ row }">
              <span v-for="name in row.config_bundlename" v-bind:key="name"
                ><li>
                  {{ name }} (<a
                    v-bind:href="
                      CDNUrl + '/' + row.config_AssetGroup + '/' + name
                    "
                    >Link</a
                  >)
                </li></span
              >
            </template>
          </el-table-column>

          <el-table-column
            label="Modifier"
            prop="config_uploader"
            width="180px"
            sortable
            :filters="filterModifiers"
            :filter-method="handleFilterUploader"
          >
            <template slot-scope="{ row }">
              <span>{{ row.config_uploader }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Actions" width="120px">
            <template slot-scope="{ row }">
              <template v-if="row.config_isActivated != 0">
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-edit-outline"
                  @click="onActive(row)"
                  >Stop!</el-button
                >
              </template>
              <template v-else>
                <template v-if="!row.editMode">
                  <el-button
                    type="success"
                    size="small"
                    icon="el-icon-edit-outline"
                    @click="onActive(row)"
                    >Active</el-button
                  >
                </template>
              </template>

              <br />
              <br />

              <template v-if="row.config_isActivated <= 0">
                <template v-if="row.editMode">
                  <template>
                    <el-button
                      type="success"
                      size="small"
                      icon="el-icon-edit-outline"
                      @click="confirmEdit(row)"
                      >Update</el-button
                    >

                    <br />
                    <br />

                    <el-button
                      class="cancel-btn"
                      size="small"
                      icon="el-icon-refresh"
                      type="warning"
                      @click="cancelEdit(row)"
                      >Cancel</el-button
                    >
                  </template>
                </template>
                <template v-else>
                  <el-button
                    type="danger"
                    size="small"
                    icon="el-icon-delete"
                    @click="onEditAsset(row)"
                    >Edit</el-button
                  >
                </template>
              </template>

              <br />
              <br />

              <template v-if="row.config_isActivated <= 0 && !row.editMode">
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  @click="onDeleteAsset(row)"
                  >Delete</el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="Add New Asset" name="2">

        <el-form ref="form" :model="addAssetsForm" label-width="200px">
          <el-form-item label="Asset Group">
            <el-select v-model="addAssetsForm.AssetGroup" placeholder="Select">
              <el-option
                v-for="item in groupList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Description">
            <el-input v-model="addAssetsForm.AssetDescription"></el-input>
          </el-form-item>

          <el-form-item label="Event ID">
            <el-select v-model="addAssetsForm.eventID" placeholder="Select">
              <el-option
                v-for="item in eventList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Note: Download Type">
            <span>- NONE: không tự động download (3D, ingame model,....).</span>
            <br />
            <span
              >- SILENT_DOWNLOAD: tự động download ngầm (configs,
              2D,....).</span
            >
            <br />
            <span>- BANNER_DOWNLOAD: download kèm theo banner của Event</span>
          </el-form-item>

          <el-form-item label="Download Type">
            <el-select
              v-model="addAssetsForm.downloadType"
              placeholder="Select"
            >
              <el-option
                v-for="item in downloadTypeList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Affect From Version" style="width: 50%">
            <el-input
              placeholder="Example: 1.10.0"
              v-model="addAssetsForm.fromVersion"
            ></el-input>
          </el-form-item>

          <el-form-item label="Affect To Version" style="width: 50%">
            <el-input
              placeholder="Example: 1.11.0"
              v-model="addAssetsForm.toVersion"
            ></el-input>
          </el-form-item>

          <el-form-item label="Bundles" style="width: 50%">
            <el-upload
              class="upload-demo"
              action=""
              ref="upload"
              :auto-upload="false"
              :multiple="true"
              :on-change="handleChange"
              :on-remove="handleRemove"
            >
              <el-button slot="trigger" size="small" type="primary"
                >Browse</el-button
              >
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onAddNewAsset"
              >Add New Asset</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="Quick Upload" name="3">

        <el-form ref="form" label-width="200px">
          <el-form-item label="Config File" style="width: 50%">
            <el-upload
              class="upload-demo"
              action=""
              :multiple="false"
              :auto-upload="false"
              ref="upload1"
              :on-change="handleChangeQuickConfig"
              :on-remove="handleRemoveQuickConfig"
            >
              <el-button slot="trigger" size="small" type="primary"
                >Browse</el-button
              >
            </el-upload>
          </el-form-item>

          <el-form-item label="Asset Files" style="width: 50%">
            <el-upload
              class="upload-demo"
              drag
              action=""
              ref="upload2"
              :auto-upload="false"
              :multiple="true"
              :on-change="handleChangeQuickAssets"
              :on-remove="handleRemoveQuickAssets"
              :file-list="uploadFileQuickAssets"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Drop asset files here!</div>
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onQuickUploadAssets"
              >Quick Upload Asset</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<style>
.el-row {
  margin-bottom: 20px;
}
</style>

<script>
var UPLOAD_FOLDER_PATH = "assets";

// Status for Apply Settings
const APPLY_STATUS_READY = 'READY';
const APPLY_STATUS_ENABLE = 'ENABLE';
const APPLY_STATUS_PROCESSING = 'PROCESSING';
const APPLY_STATUS_DONE = 'DONE';

import setting from "@/settings.js";
import { MessageBox, Message } from "element-ui";
import { Loading } from "element-ui";
import { isStringEmpty } from "@/utils/validate";
import { getOSToken } from "@/utils/auth";
import {
  getAssetsDataFromFirebase,
  activateAssetsDataFromFirebase,
  deleteAssetsFromFirebase,
  uploadAssetsToFirebase,
  editAssetsToFirebase,
  dynamic_assets_holdingSystemToFirebase,
  quickUploadAssetsToFirebase,
  copy,
} from "@/api/dynamic_assets";

import {
  UploadFileToRemoteCDN,
  UploadFileStatus,
} from "@/api/gameservice";

import apiGeneral from "@/api/apiGeneral.js";

import {
  events_holdingSystemToFirebase,
  getEventDataFromFirebase,
} from "@/api/dynamic_events";

import store from "@/store";
var fs = require("fs");

export default {
  name: "Assets",
  components: {},
  data() {
    return {
      addAssetsForm: {
        AssetGroup: "",
        AssetDescription: "",
        eventID: "",
        downloadType: "",
        fromVersion: "",
        toVersion: "",
        user: "",
        fileName: "",
      },

      pickerOptions: {
        shortcuts: [
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      groupList: [
        "AVATAR_FRAME",
        "HERO",
        "GUN",
        "DRONE",
        "DECO",
        "OTHERS",
        "CONFIG",
      ],
      configDataList: [],
      downloadTypeList: ["NONE", "SILENT_DOWNLOAD", "BANNER_DOWNLOAD"],
      eventList: [],
      uploadFileList: [],
      uploadFileQuickConfig: [],
      uploadFileQuickAssets: [],
      filterModifiers: [],
      loading: false,
      fromVersion: "",
      toVersion: "",
      searchString: "",
      activeNames: "1",
      isHolding: 0,
      modify: "Hello Admin!",
      log_error: "log_error",
      cachedData: undefined,
      CDNUrl: setting.download_file_CDN + "/" + UPLOAD_FOLDER_PATH,
      //----------------------------------
      syncCDNStatus: '',
      syncCDNProcessingTimeInc: 0,
      isSyncCDNTimerRun: false,
      
      // Apply Settings Statuses
      APPLY_STATUS_READY: APPLY_STATUS_READY,
      APPLY_STATUS_ENABLE: APPLY_STATUS_ENABLE,
      APPLY_STATUS_PROCESSING: APPLY_STATUS_PROCESSING,
      APPLY_STATUS_DONE: APPLY_STATUS_DONE,
      //----------------------------------
    };
  },
  created() {
    this.loading = true;
    this.configDataList = [];
    this.eventList = [];
    this.configNameList = [];
    this.loading = false;
    this.modify = "Hello Admin!";

    this.getAssetDataList();
    this.getEventDataList();
    this.onGetUploadFileStatus();

    // check OS
    let OS = getOSToken();

    if (OS && OS.toLowerCase() === "ios") {
      this.CDNUrl = setting.download_file_CDN_ios + "/" + UPLOAD_FOLDER_PATH;
    } else {
      this.CDNUrl = setting.download_file_CDN + "/" + UPLOAD_FOLDER_PATH;
    }
  },
  methods: {
    //------------------------------------------------------------------------------------
    // get config data list
    getAssetDataList() {
      this.loading = true;
      this.configDataList = [];
      this.filterModifiers = [];
      let filterCheck = [];

      getAssetsDataFromFirebase()
        .then((response) => {
          let fromDateTime = new Date(response.modify_datetime);
          this.modify = fromDateTime.toString() + " by " + response.modify_user;
          this.isHolding = response.is_holding;

          //Message({message: "fetch config data list length: " + response.assets.length, type: "error", duration: 5 * 1000});
          response.assets.forEach((i) => {
            this.configDataList.push({
              config_id: i.id,
              config_AssetGroup: i.asset_group,
              config_AssetDescription: i.asset_description,
              config_EventID: i.event_id,
              config_DownloadType: i.download_type,
              config_fromVersion: i.affect_from_version,
              config_toVersion: i.affect_to_version,

              config_bundlename: i.file_name_list,
              config_uploader: i.uploader,
              config_isActivated: i.activated,

              editMode: false,
              deleteMode: false,
            });
            if (filterCheck.includes(i.uploader) === false) {            
              this.filterModifiers.push({text: i.uploader, value: i.uploader})
              filterCheck.push(i.uploader);
            }
          });

          this.loading = false;
        })
        .catch((error) => {
          Message({
            message: "Can't fetch config data list " + error,
            type: "error",
            duration: 5 * 1000,
          });
          this.loading = false;
        });
    },

    //------------------------------------------------------------------------------------
    // get config data list
    getEventDataList() {
      this.loading = true;
      this.eventList = [];

      getEventDataFromFirebase()
        .then((response) => {
          this.eventList.push("NONE");

          response.events.forEach((i) => {
            if (this.eventList.indexOf(i.event_id) === -1) {
              this.eventList.push(i.event_id);
            }
          });

          this.loading = false;
        })
        .catch((error) => {
          Message({
            message: "Can't fetch event config data list",
            type: "error",
            duration: 5 * 1000,
          });
          this.loading = false;
        });
    },
    onGetUploadFileStatus() {
      
      UploadFileStatus("status_polling")
      .then(response => {

        //Message({message: "on Get Upload File Status:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

          let code = response.data.code;
          let message = response.data.message;

          if (message === APPLY_STATUS_PROCESSING ||
              message === APPLY_STATUS_DONE ||
              message === APPLY_STATUS_ENABLE ||
              message === APPLY_STATUS_READY)
          {
            if (message === APPLY_STATUS_READY && this.syncCDNStatus === APPLY_STATUS_PROCESSING)
            {
              message = APPLY_STATUS_DONE;
            }

            if (message === APPLY_STATUS_DONE)
            {
              Message({message: "Synchronize CDN ..." + message, type: "success", duration: 5 * 1000});
            }

            this.syncCDNStatus = message;

            // start a timer to checking status processing next status ... 
            //
            if (this.syncCDNStatus === APPLY_STATUS_PROCESSING)
            {
              // enable loading
              this.loading = true;

              // get information of processing
              let interval = 0;
              
              if (response.data.info)
              {
                let info = JSON.parse(response.data.info);
                if (info && info.timestamp)
                {
                  let timestamp = Math.floor(Date.now()/1000);
                  interval = timestamp - info.timestamp;
                  if (interval < 0)
                  {
                    interval = 0;
                  }
                }
              }

              this.syncCDNProcessingTimeInc = interval;

              // If message is processing auto recheck status continuely
              if (!this.isSyncCDNTimerRun)
              {
                this.isSyncCDNTimerRun = true;
                setTimeout(() =>
                {
                  this.onGetUploadFileStatus();                    
                  this.isSyncCDNTimerRun = false;
                }, 5000);    
              }      
            }else{
                //disable loading
                this.loading = false;
            }

            //Message({message: "Synchronize CDN ... " + this.syncCDNStatus, type: "success", duration: 5 * 1000});
          }else //other status
          {
            Message({message: "Recheck Status ... Unknown status " + message, type: "error", duration: 5 * 1000});
          }
      });
    },
    onSyncCDN() {

      UploadFileStatus("sync_cdn_polling")
        .then(response => {

          //this.loading = false;
          //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

          let code = response.data.code;
          if (code === 0)
          {
            Message({message: "[SUCCESS] Synchronize file to remote CDN server", type: "success", duration: 5 * 1000});

            let message = response.data.message;

            this.syncCDNStatus = message;

            // If message is processing auto recheck status continuously
            if (this.syncCDNStatus === APPLY_STATUS_PROCESSING)
            {
              this.syncCDNProcessingTimeInc = 0;
              this.isSyncCDNTimerRun = false;
              this.onGetUploadFileStatus();
            }
          }else
          {
            Message({message: "[FAILED] Synchronize file to remote CDN server", type: "error", duration: 5 * 1000});
          }
        });
    },
    //------------------------------------------------------------------------------------
    // hold method
    onHold() {
      if (this.isHolding === 0) {
        MessageBox.confirm(
          "Do you confirm to HOLD this system?",
          "Confirm HOLD",
          {
            confirmButtonText: "Hold",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.loading = true;

            let promises = [];

            const funcHoldingSystemToFirebase = async (
              is_event,
              is_holding,
              user
            ) => {
              let ret = undefined;

              if (is_event) {
                ret = await events_holdingSystemToFirebase(is_holding, user);
              } else {
                ret = await dynamic_assets_holdingSystemToFirebase(
                  is_holding,
                  user
                );
              }

              return ret;
            };

            promises.push(
              funcHoldingSystemToFirebase(true, 1, store.getters.email)
            );
            promises.push(
              funcHoldingSystemToFirebase(false, 1, store.getters.email)
            );

            Promise.all(promises).then((response) => {
              let success = true;

              for (let i = 0; i < response.length; i++) {
                if (response[i].is_holding === 0) {
                  success = false;
                  break;
                }
              }

              if (success) {
                this.isHolding = 1;
                Message({
                  message: "Holding Success!",
                  type: "success",
                  duration: 5 * 1000,
                });
              } else {
                this.isHolding = 0;
                Message({
                  message: "Holding Failed!",
                  type: "error",
                  duration: 5 * 1000,
                });
              }

              this.loading = false;
            });
          })
          .catch((error) => {
            // do nothing
          });
      } else {
        MessageBox.confirm(
          "Do you confirm to ACTIVE this system?",
          "Confirm ACTIVE",
          {
            confirmButtonText: "Active",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.loading = true;

            let promises = [];

            const funcHoldingSystemToFirebase = async (
              is_event,
              is_holding,
              user
            ) => {
              let ret = undefined;

              if (is_event) {
                ret = await events_holdingSystemToFirebase(is_holding, user);
              } else {
                ret = await dynamic_assets_holdingSystemToFirebase(
                  is_holding,
                  user
                );
              }

              return ret;
            };

            promises.push(
              funcHoldingSystemToFirebase(true, 0, store.getters.email)
            );
            promises.push(
              funcHoldingSystemToFirebase(false, 0, store.getters.email)
            );

            Promise.all(promises).then((response) => {
              let success = true;

              for (let i = 0; i < response.length; i++) {
                if (!response[i]) {
                  success = false;
                  break;
                }
              }

              if (success) {
                this.isHolding = 0;
                Message({
                  message: "Activating Success!",
                  type: "success",
                  duration: 5 * 1000,
                });
              } else {
                this.isHolding = 1;
                Message({
                  message: "Activating Failed!",
                  type: "error",
                  duration: 5 * 1000,
                });
              }

              this.loading = false;
            });
          })
          .catch((error) => {
            // do nothing
          });
      }
    },

    //------------------------------------------------------------------------------------
    // search method
    onSearch() {
      this.loading = true;
      this.configDataList = [];

      let _searchString = this.searchString;
      getAssetsDataFromFirebase()
        .then((response) => {
          let fromDateTime = new Date(response.modify_datetime);
          this.modify = fromDateTime.toString() + " by " + response.modify_user;
          response.assets.forEach((i) => {
            //fromDateTime = new Date(i.datetime);
            let itemData = {
              config_id: i.id,
              config_AssetGroup: i.asset_group,
              config_AssetDescription: i.asset_description,
              config_EventID: i.event_id,
              config_DownloadType: i.download_type,
              config_fromVersion: i.affect_from_version,
              config_toVersion: i.affect_to_version,

              config_bundlename: i.file_name_list,
              config_uploader: i.uploader,
              config_isActivated: i.activated,

              editMode: false,
              deleteMode: false,
            };

            if (_searchString) {
              let itemString = JSON.stringify(itemData);
              if (itemString.includes(_searchString)) {
                this.configDataList.push(itemData);
              }
            } else {
              this.configDataList.push(itemData);
            }
          });

          this.loading = false;
        })
        .catch((error) => {
          Message({
            message: "Can't fetch config data list",
            type: "error",
            duration: 5 * 1000,
          });
          this.loading = false;
        });
    },

    //------------------------------------------------------------------------------------
    onEditAsset(row) {
      this.cachedData = copy(this.configDataList);
      row.editMode = true;
    },

    //------------------------------------------------------------------------------------
    cancelEdit(row) {
      this.configDataList = copy(this.cachedData);
      row.editMode = false;
    },

    //------------------------------------------------------------------------------------
    confirmEdit(row) {
      if (isStringEmpty(row.config_AssetGroup)) {
        Message({
          message: "Asset Name is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(row.config_AssetDescription)) {
        Message({
          message: "Asset Description is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      editAssetsToFirebase(
        row.config_id,
        row.config_AssetGroup,
        row.config_AssetDescription,
        row.config_EventID,
        row.config_DownloadType,
        row.config_fromVersion,
        row.config_toVersion,
        row.config_isActivated,
        store.getters.email
      )
        .then((response) => {
          row.editMode = false;

          this.loading = false;
          Message({
            message: "Update Success!",
            type: "success",
            duration: 5 * 1000,
          });

          this.getAssetDataList();
        })
        .catch((error) => {
          this.loading = false;

          row.editMode = false;

          Message({
            message: "Update Failed! " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },

    //------------------------------------------------------------------------------------
    // stop config data
    onActive(row) {
      let count = -1;
      let rowID = 0;
      let rowIdx = 0;

      this.configDataList.forEach((i) => {
        count++;
        if (i.config_id === row.config_id) {
          rowIdx = count;
          rowID = row.config_id;
        }
      });

      let isTurnActive = false;
      let MsgMessage;
      let confirmButtonText;
      let activeValueNew = 0;

      if (this.configDataList[rowIdx].config_isActivated <= 0) {
        isTurnActive = true;
        activeValueNew = 1;
        MsgMessage =
          "Do you confirm START this Asset: " +
          row.config_AssetDescription +
          "?";
        confirmButtonText = "Activate";
      } else {
        MsgMessage =
          "Do you confirm STOP this Asset: " +
          row.config_AssetDescription +
          "?";
        confirmButtonText = "Stop";
        activeValueNew = 0;
      }

      MessageBox.confirm(MsgMessage, "Confirm activate", {
        confirmButtonText: confirmButtonText,
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.loading = true;

        this.configDataList[rowIdx].config_isActivated = activeValueNew;

        this.loading = false;

        row.editMode = false;

        activateAssetsDataFromFirebase(
          rowID,
          store.getters.email,
          activeValueNew
        )
          .then((response) => {
            Message({
              message:
                confirmButtonText +
                " Asset " +
                row.config_AssetDescription +
                " success!", // + rowID + "-" + activeValueNew,
              type: "success",
              duration: 5 * 1000,
            });

            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },

    //------------------------------------------------------------------------------------
    // delete config data
    onDeleteAsset(row) {
      let count = -1;
      let rowIdx = 0;
      let rowID = 0;

      this.configDataList.forEach((i) => {
        count++;
        if (i.config_id === row.config_id) {
          rowIdx = count;
          rowID = i.config_id;
        }
      });

      var AssetDescription = row.config_AssetDescription;

      MessageBox.confirm(
        "Do you confirm delete this Asset: " + AssetDescription + "?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;

        this.configDataList.splice(rowIdx, 1);

        this.loading = false;

        deleteAssetsFromFirebase(rowID, store.getters.email)
          .then((response) => {
            Message({
              message: "Delete Asset Data success!",
              type: "success",
              duration: 5 * 1000,
            });

            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },

    //------------------------------------------------------------------------------------
    // upload one file
    async uploadOneFile(file, fileName, assetGroupName) {
      let result = undefined;
      let filePath = UPLOAD_FOLDER_PATH + "/" + assetGroupName;

      console.log("fileName = " + fileName + "   path = " + filePath);

      await UploadFileToRemoteCDN(file.raw, fileName, filePath)
        .then((response) => {
          //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});
          let code = response.data.code;
          if (code === 0) {
            result = "success";
          } else {
            result = "failed";
          }
        })
        .catch((error) => {
          result = "failed";
        });

      return result;
    },

    //------------------------------------------------------------------------------------
    // upload multi file
    async uploadMultiFile(fileList, nowTime, assetGroup) {
      // const promises = [];
      let fileName = "unknown";
      let result = [];

      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        let assetGroupName = assetGroup[i];

        fileName = "unknown";
        if (file.name) {
          fileName = file.name;
        }

        fileName += "_" + nowTime;

        await this.uploadOneFile(file, fileName, assetGroupName)
          .then((response) => {
            let data = {
              file_name: file.name,
              result: response,
            };
            result.push(data);
          })
          .catch((error) => {
            let data = {
              file_name: file.name,
              result: "failed",
            };
            result.push(data);
          });
      }

      return result;
    },

    //------------------------------------------------------------------------------------
    // add config name
    onAddNewAsset() {
      if (isStringEmpty(this.addAssetsForm.AssetGroup)) {
        Message({
          message: "Asset Group: is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(this.addAssetsForm.AssetDescription)) {
        Message({
          message: "Asset Description: is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(this.addAssetsForm.fromVersion)) {
        Message({
          message: "Affect From Version: is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(this.addAssetsForm.toVersion)) {
        Message({
          message: "Affect To Version: is empty!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (this.uploadFileList.count === 0) {
        Message({
          message: "Please browse a bundle file!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      // default not active event
      let isActivated = 0;
      this.loading = true;
      var nowTime = Date.now();
      let promises = [];
      let assetGroup = [];
      for (let i = 0; i < this.uploadFileList.length; i++) {
        assetGroup.push(this.addAssetsForm.AssetGroup);
      }

      promises.push(
        this.uploadMultiFile(this.uploadFileList, nowTime, assetGroup)
      );
      Promise.all(promises)
        .then((response) => {
          this.loading = false;
          let count = 0;
          let errorFile = "";

          var arrayResponse = response[0];
          arrayResponse.forEach((data) => {
            if (data.result === "success") {
              count++;
            } else {
              errorFile += data.file_name + ",";
            }
          });

          if (count === response.length) {
            let fileNameListMain = [];
            let fileSizeListMain = [];
            let fileName = "unknown";
            this.uploadFileList.forEach((file) => {
              fileName = "unknown";
              if (file.name) {
                fileName = file.name;
              }

              fileName += "_" + nowTime;

              fileNameListMain.push(fileName);
              fileSizeListMain.push(file.size);
            });

            uploadAssetsToFirebase(
              this.addAssetsForm.AssetGroup,
              this.addAssetsForm.AssetDescription,
              this.addAssetsForm.eventID,
              this.addAssetsForm.downloadType,
              this.addAssetsForm.fromVersion,
              this.addAssetsForm.toVersion,
              isActivated,
              store.getters.email,
              fileNameListMain,
              fileSizeListMain
            )
              .then((response) => {
                Message({
                  message: "Added Success!",
                  type: "success",
                  duration: 5 * 1000,
                });

                this.addAssetsForm.AssetGroup = "";
                this.addAssetsForm.AssetDescription = "";
                this.addAssetsForm.eventID = "";
                this.addAssetsForm.downloadType = "";
                this.addAssetsForm.fromVersion = "";
                this.addAssetsForm.toVersion = "";
                this.$refs.upload.clearFiles();

                this.getAssetDataList();
                this.onGetUploadFileStatus();
              })
              .catch((error) => {
                this.loading = false;
                Message({
                  message: "Added Failed! " + error,
                  type: "error",
                  duration: 5 * 1000,
                });
              });
          } else {
            Message({
              message: "Added Failed! File List: " + errorFile,
              type: "error",
              duration: 5 * 1000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Added Failed! " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },

    //------------------------------------------------------------------------------------
    // quick upload assets
    onQuickUploadAssets() {
      if (
        this.uploadFileQuickConfig.length === undefined ||
        this.uploadFileQuickConfig.length === 0
      ) {
        Message({
          message: "Please browse a config file!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (
        this.uploadFileQuickAssets.length === undefined ||
        this.uploadFileQuickAssets.length === 0
      ) {
        Message({
          message: "Please browse a asset file!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      this.loading = true;

      // parsing config file
      let fileData = undefined;
      var reader = new FileReader();
      reader.readAsText(this.uploadFileQuickConfig[0].raw);
      reader.onload = (event) => {
        fileData = event.target.result;

        if (fileData === undefined) {
          Message({
            message: "Can't parsing file to upload",
            type: "error",
            duration: 5 * 1000,
          });

          this.loading = false;

          return;
        }
        var configData = apiGeneral.convertCSVToJson(fileData);

        // kiem tra xem config voi file upload co dung hay khong.
        var nowTime = Date.now();
        let countNumberOfBundleFile = 0;
        let hasSpace = false;

        let assetGroup = [];

        this.uploadFileQuickAssets.forEach((file) => {
          let hasBundleName = false;
          let fileNameList = [];
          let fileSizeList = [];
          let fileName = "unknown";

          if (file.name) {
            fileName = file.name;
          }

          fileName += "_" + nowTime;

          fileNameList.push(fileName);
          fileSizeList.push(file.size);

          configData.forEach((oneRowData) => {

            //check space
            if (oneRowData["AssetGroup"].indexOf(' ') >= 0) {
              hasSpace = true;
              oneRowData["AssetGroup"] = oneRowData["AssetGroup"].replace(/\s/g, "");
            }

            if (oneRowData["EventID"].indexOf(' ') >= 0) {
              hasSpace = true;
              oneRowData["EventID"] = oneRowData["EventID"].replace(/\s/g, "");
            }

            if (oneRowData["AffectFromVersion"].indexOf(' ') >= 0) {
              hasSpace = true;
              oneRowData["AffectFromVersion"] = oneRowData["AffectFromVersion"].replace(/\s/g, "");
            }

            if (oneRowData["AffectToVersion"].indexOf(' ') >= 0) {
              hasSpace = true;
              oneRowData["AffectToVersion"] = oneRowData["AffectToVersion"].replace(/\s/g, "");
            }

            if (oneRowData["DownloadType"].indexOf(' ') >= 0) {
              hasSpace = true;
              oneRowData["DownloadType"] = oneRowData["DownloadType"].replace(/\s/g, "");
            }

            if (oneRowData["BundleName"].indexOf(' ') >= 0) {
              hasSpace = true;
              oneRowData["BundleName"] = oneRowData["BundleName"].replace(/\s/g, "");
            }
            
            if (oneRowData["BundleName"] === file.name) {
              countNumberOfBundleFile++;
              hasBundleName = true;
              oneRowData["fileNameList"] = fileNameList;
              oneRowData["fileSizeList"] = fileSizeList;
              assetGroup.push(oneRowData["AssetGroup"]);
            }
          });

          if (hasBundleName === false) {
            Message({
              message: "Missing bundle name: " + file.name,
              type: "error",
              duration: 5 * 1000,
            });

            console.log("Missing bundle name: " + file.name);

            this.loading = false;

            return;
          }
        });

        if (configData.length !== countNumberOfBundleFile) {
          Message({
            message: "File list in Config is not same with Asset file list!",
            type: "error",
            duration: 5 * 1000,
          });

          this.loading = false;

          return;
        }

        console.log("configData.length = " + configData.length);

        // upload config file
        // default not active event
        let isActivated = 0;
        let promises = [];
        let list_out = [];

        promises.push(
          this.uploadMultiFile(this.uploadFileQuickAssets, nowTime, assetGroup)
        );
        Promise.all(promises)
          .then((response) => {
            this.loading = false;
            let count = 0;

            var arrayResponseQuickAssets = response[0];
            for (let i = 0; i < arrayResponseQuickAssets.length; i++) {
              if (arrayResponseQuickAssets[i].result === "success") {
                count++;
              }
            }

            if (count === arrayResponseQuickAssets.length) {
              quickUploadAssetsToFirebase(configData, store.getters.email)
                .then((response) => {

                  if (hasSpace) {
                    Message({
                      message: "Added Success but have Space in QuickConfig!",
                      type: "warning",
                      duration: 5 * 1000,
                    });
                  }
                  else 
                  {
                    Message({
                      message: "Added Success!",
                      type: "success",
                      duration: 5 * 1000,
                    });
                  }

                  this.$refs.upload1.clearFiles();
                  this.$refs.upload2.clearFiles();

                  this.getAssetDataList();
                  this.onGetUploadFileStatus();
                })
                .catch((error) => {
                  this.loading = false;
                  Message({
                    message: "Added Failed! " + error,
                    type: "error",
                    duration: 5 * 1000,
                  });
                });
            } else {
              Message({
                message: "Added Failed! ",
                type: "error",
                duration: 5 * 1000,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            Message({
              message: "Added Failed! " + error,
              type: "error",
              duration: 5 * 1000,
            });
          });
      };
    },

    //------------------------------------------------------------------------------------
    // handle Remove and change Browse File
    handleRemove(file, fileList) {
      this.uploadFileList = fileList;
    },

    //------------------------------------------------------------------------------------
    handleChange(file, fileList) {
      this.uploadFileList = fileList;
    },
    //------------------------------------------------------------------------------------
    // handle Remove and change Browse File
    handleChangeQuickConfig(file, fileList) {
      this.uploadFileQuickConfig = fileList;
    },

    //------------------------------------------------------------------------------------
    handleRemoveQuickConfig(file, fileList) {
      this.uploadFileQuickConfig = fileList;
    },
    //------------------------------------------------------------------------------------
    // handle Remove and change Browse File
    handleChangeQuickAssets(file, fileList) {
      this.uploadFileQuickAssets = fileList;
    },

    //------------------------------------------------------------------------------------
    handleRemoveQuickAssets(file, fileList) {
      this.uploadFileQuickAssets = fileList;
    },
    //------------------------------------------------------------------------------------
    // others
    handleClick(obj, e) {
      if (obj.label === "Asset List") {
        // refresh upload file status
        this.onGetUploadFileStatus();
      }
    },
    //------------------------------------------------------------------------------------
    // handleFilterUploader
    handleFilterUploader(value, row) {
      return row.config_uploader === value;
    },
  },
};
</script>

<style lang="scss" scoped>
.events-container {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
</style>
